import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import ProductsList from "../components/productsList";
import SEO from '../components/seo';

const Catalog = ({ data }) => {
  //filter per category
  const category = data.category.name;
  const products = data.allProduct.nodes.filter(product => product.category.replace(/\s+/g, " ").includes(category))
  return (
    <Layout>
      <SEO title={category}/>
      <div className="container">
     <h1>{data.category.name}</h1>
     <ProductsList products={products}/>
     </div>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    category(id: {eq: $id}) {
      name
      id
    }
    allProduct {
      nodes {
        id
        data_id
        category
        brand
        description
        product_name
        sku
        price
        is_product_hidden
        product_image_link
        product_link
        }
      }
  }
`

export default Catalog